import React from "react";

import classNames from "classnames";
import { Container, Image, HighlightedText } from "@atoms";
import { HeadingWIcon, Slideshow } from "@molecules";
import brand from "@utils/brand";

const HeroSimple = ({
  backgroundImage,
  backgroundImages,
  heading,
  highlightText,
  copy,
  icon,
  caption,
  extraPadding,
}) => {
  const { romero } = brand;
  return (
    <div
      className={classNames(
        "relative flex w-full flex-col overflow-hidden bg-white",
        {
          "pb-20 sm:pb-32": extraPadding && !romero,
          "pb-6": !extraPadding && !romero,
          "min-h-1/3-screen pt-48": !romero,
          "mt-20 justify-center": romero,
          "h-[calc(100vh_-_5rem)]": romero && heading,
          "min-h-[33vh] md:min-h-[66vh]": romero && !heading,
        }
      )}
    >
      <div
        className={classNames(
          "absolute inset-0 z-0",
          { "bg-brown": !romero },
          { "bg-blue-dark": romero }
        )}
      >
        {/* {/* <div className="absolute transform rotate-180 top-0 left-0 right-0 h-48 bg-gradient-to-b from-transparent to-black opacity-50 z-10" /> */}
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/75 via-transparent to-black/50" />
        <div
          className={classNames("absolute inset-0 z-0", {
            "opacity-80": romero,
          })}
        >
          {backgroundImage && (
            <Image
              image={backgroundImage}
              ixParams={heading ? { exp: "-5", con: 5 } : {}}
              fill
            />
          )}
          {backgroundImages && !backgroundImage && (
            <Slideshow
              slides={backgroundImages}
              interval={5000}
              ixParams={{ exp: "-3", con: 5 }}
              fill
            />
          )}
        </div>
      </div>
      {heading && (
        <div
          className={classNames("relative z-10", {
            "mt-auto": !romero,
          })}
        >
          <Container>
            {romero && (
              <div className="flex flex-col items-center">
                <div className="w-full max-w-3xl text-white">
                  <HighlightedText
                    text={heading}
                    highlightText={highlightText}
                    variant="h1"
                    tag="h1"
                    animate
                    centered
                    block
                  />
                </div>
              </div>
            )}
            {!romero && (
              <div className="mb-6 max-w-xl">
                <HeadingWIcon
                  icon={icon}
                  heading={heading}
                  copy={copy}
                  inverse
                />
              </div>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

HeroSimple.defaultProps = {
  extraPadding: false,
  caption: null,
};

export default HeroSimple;
